import { render, staticRenderFns } from "./join-event.html?vue&type=template&id=072597a4&scoped=true&"
import script from "./join-event.vue?vue&type=script&lang=ts&"
export * from "./join-event.vue?vue&type=script&lang=ts&"
import style0 from "./join-event.scss?vue&type=style&index=0&id=072597a4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072597a4",
  null
  
)

export default component.exports