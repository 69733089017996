<template>
  <div>

    <promo-side-bar-left
      v-if="!edit"
      @promoGoto="goToPromoPage($event)"
    />
    <promo-side-bar-right
      v-if="!edit"
    />

    <transition name="fade-event-access-popup">
      <div class="event-disabled-popup" v-if="isEventDisabledPopupVisible">
        <div class="boxie">
          <div class="title">{{ $t('eventIsDisabledPopup.title') }}</div>
          <div>
            <button @click="closeEventDisabledPopup()">{{ $t('button.close') }}</button>
          </div>
        </div>
      </div>
    </transition>

    <div class="circular-progress-overlay" v-if="!eventInfo.id">
      <mu-circular-progress class="demo-circular-progress" :size="26"></mu-circular-progress>
    </div>

    <div class="main section section-event-page">
      <div v-if="!edit">

        <div class="section section-hero"
             :style="heroSectionStyles"
             :class="{
                'hero-light': isSectionHeroDarkenerDisabled
             }"
        >
          <div class="section-body">
            <div class="title-block">
              <div class="pre-title">{{ eventInfo.event_type.name }}</div>
              <div
                class="title"
                :class="eventTitleClasses"
              >
                {{ eventInfo.title }}
              </div>
              <div class="subtitle" v-if="eventInfo.id">
                <div class="wrap">
                  <div class="wrap-title">
                    <!--                    <div class="title">Event time according to your time zone</div>-->
                    <div class="time-wrap">
                      <span>{{ $moment(eventInfo.date_start).format('DD MMM YYYY HH:mm') }}</span>
                      <span v-if="endDateNeeded()"> - {{
                          $moment(eventInfo.date_end).format('DD MMM YYYY HH:mm')
                        }}</span>
                    </div>
                  </div>
                  <!--                  <div class="wrap-title">-->
                  <!--                    <div class="title">Time in the local time zone of the event location</div>-->
                  <!--                    <div class="time-wrap">-->
                  <!--                      <span>{{ transformEventDate(eventInfo.date_start) }}</span>-->
                  <!--                      <span v-if="endDateNeeded()"> - {{ transformEventDate(eventInfo.date_end) }}</span>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
                <div class="wrap">
<!--                  <div class="wrap-title">{{ eventInfo.country.name }}<span-->
<!--                    v-if="eventInfo.country.name && eventInfo.city.name">,</span>-->
<!--                    {{ eventInfo.city.name }}-->
<!--                  </div>-->
<!--                  <div class="wrap-subtitle" :class="getAddressClassNames()">{{ eventInfo.address }}</div>-->
                </div>
              </div>
            </div>
            <div class="section-hero-actions">
              <div class="part part-1">
                <div
                  class="logo-box organizer-logo" v-if="eventInfo.org_logo"
                  :style="{ 'background-image' : 'url(' + eventInfo.org_logo + ')' }"
                />
                <div class="logo-box qr">
                  <qrcode-vue
                    class="qr-canvas"
                    :size="104"
                    :value="qrCodeUrl"
                    level="Q"
                  />
                </div>
                <!--
                <div class="event-online-statuses">
                  <div class="event-online-status event-online-status-online  event-online-status-inactive">
                    {{ $t('events.page.eventOnline') }}
                  </div>
                  <div class="event-online-status event-online-status-offline event-online-status-active">
                    {{ $t('events.page.eventOffline') }}
                  </div>
                </div>
                -->
              </div>

              <div class="part part-2">

                <!--<div class="access-status" v-if="eventInfo.access_type === 'free'">{{ $t('title.freeEnter') }}</div>-->

                <div class="button-wrapper">

                  <a
                    class="control control-button control-button-primary"
                    :class="{
                       'control-button-quick-register': getTicketsButtonAttributes.target === '_self'
                    }"
                    v-if="getTicketsButtonAttributes.href"
                    :href="getTicketsButtonAttributes.href"
                    :target="getTicketsButtonAttributes.target"
                  >
                    {{ getTicketsButtonAttributes.buttonText }}
                  </a>

                  <!--<a class="control control-button control-button-primary"
                          v-if="eventInfo.buy_booth_url"
                          :href="eventInfo.buy_booth_url"
                          target="_blank">
                    {{$t('button.requestABooth')}}
                  </a>-->

                  <button class="control control-button control-button-secondary"
                          :style="'background-color: #ff0000'"
                          v-if="!isAuthenticated && !isAuthLoading"
                          @click="showAuthPopup()">
                    {{ $t('title.login') }}
                  </button>

                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="section section-qr" v-if="showSections.qr">
          <div class="section-body">
            <div class="section-title">
              <div class="title">{{ $t('metaTagTemplates.eventPage.mobileStoreInfo.title') }} </div>
            </div>
            <div class="section-content">
              <div class="content content-qr ws-pre-wrap">{{ $t('metaTagTemplates.eventPage.mobileStoreInfo.description') }}
              </div>
              <div class="qr-block">
                <div
                  class="android-block"
                  v-if="isCN"
                >
                  <a :href="androidApkUrl">
                    <div class="apk-button"></div>
                  </a>
                  <div class="android-qr android-qr-cn"></div>
                </div>
                <div
                  class="android-block"
                  v-else
                >
                  <a target="_blank"
                     href="https://play.google.com/store/apps/details?id=com.biletum">
                    <div class="android-button"></div>
                  </a>
                  <div class="android-qr"></div>
                </div>
                <div class="ios-block">
                  <a target="_blank"
                     :href="iosAppUrl">
                    <div class="ios-button"></div>
                  </a>
                  <div class="ios-qr">
                    <qrcode-vue
                      class="qr-canvas"
                      :size="100"
                      :value="iosAppQrCodeContents"
                      level="Q"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section section-about-event" v-if="showSections.about">
          <div class="section-body">
            <div class="section-title">
              <div class="title">{{ $t('events.page.about_event_title') }}</div>
            </div>
            <!--            <div class="statistics-block">-->
            <!--              <div class="block-wrap">-->
            <!--                <div class="block-item">-->
            <!--                  <div class="title">80 000</div>-->
            <!--                  <div class="subtitle">{{ $t('events.page.attendees') }}</div>-->
            <!--                </div>-->
            <!--                <div class="block-item">-->
            <!--                  <div class="title">1 100</div>-->
            <!--                  <div class="subtitle">{{ $t('events.page.exhibitors') }}</div>-->
            <!--                </div>-->
            <!--                <div class="block-item">-->
            <!--                  <div class="title">6 000</div>-->
            <!--                  <div class="subtitle">{{ $t('events.page.contractsSigned') }}</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="section-content ugc">
              <div class="content content-agenda">
                <ul v-if="preparedAgenda.length">
                  <li
                    v-for="(agendaItem, index) in preparedAgenda"
                    :key="index"
                    v-html="linkifyUrls(agendaItem)"
                  />
                </ul>
              </div>
              <div
                class="content content-descr ws-pre-wrap"
                v-html="linkifyUrls(eventInfo.descr)"
              />
            </div>
          </div>
        </div>

        <div class="section section-media" v-if="showSections.media">
          <div class="section-body">
            <div class="section-title">
              <div class="title">{{ $t('events.page.media_title') }}</div>
            </div>
            <div class="carousel-wrap">
              <mu-carousel
                :hide-indicators="eventInfo.photos.length < 2"
                :hide-controls="eventInfo.photos.length < 2">
                <mu-carousel-item v-for="(item, index) in eventInfo.photos" :key="index">
                  <img :src="item" alt="img"/>
                </mu-carousel-item>
              </mu-carousel>
            </div>
          </div>
        </div>

        <div class="section section-venue" v-if="showSections.venue">
          <div class="section-body">
            <div class="section-title">
              <div class="title">{{ $t('events.page.venue_title') }}</div>
            </div>
            <div class="badge-section-content">
              {{ $t('events.page.address_title') }}:
              {{ eventInfo.address }},
              {{ eventInfo.country.name }},
              {{ eventInfo.city.name }}
            </div>
          </div>
        </div>

        <div class="section section-venue-map" v-if="showSections.venue">
          <div class="section-body">
            <div class="section-content">
              <div class="address-badge">
                <div class="badge-title">{{ $t('events.page.location_title') }}</div>
                <div class="badge-section" v-if="eventInfo.venue_title">
                  <div class="badge-title badge-section-title">{{ $t('events.page.venue_title') }}:</div>
                  <div class="badge-section-content">
                    {{ eventInfo.venue_title }}
                  </div>
                </div>
                <div class="badge-section">
                  <div class="badge-title badge-section-title">{{ $t('events.page.address_title') }}:</div>
                  <div class="badge-section-content">
                    {{ eventInfo.address }},
                    {{ eventInfo.country.name }},
                    {{ eventInfo.city.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="map-wrapper">
            <location-picker
              v-if="getMapProvider === 'google'"
              :hasPicker="false"
              :address="locationPickerAddress"
              :mapData="eventInfo.map"
            ></location-picker>
            <baidu-map
              v-if="getMapProvider === 'baidu'"
              :country="eventInfo.country"
              :city="eventInfo.city"
              :mapData="eventInfo.map"
            ></baidu-map>
          </div>
        </div>

        <div class="section section-contacts" v-if="showSections.contacts">
          <div class="section-body">
            <div class="section-title">
              <div class="title">{{ $t('events.page.contacts_title') }}</div>
            </div>
            <div class="section-content">
              <div class="content-item" v-if="eventInfo.org_name">
                <div class="title">{{ $t('contacts.info.organizer') }}</div>
                <div class="content">{{ eventInfo.org_name }}</div>
              </div>
              <div class="content-item" v-if="eventInfo.org_phone">
                <div class="title">{{ $t('contacts.info.phone') }}</div>
                <div class="content">{{ eventInfo.org_phone }}</div>
              </div>
              <div class="content-item" v-if="eventInfo.org_email">
                <div class="title">{{ $t('contacts.info.email') }}</div>
                <div class="content">{{ eventInfo.org_email }}</div>
              </div>
              <div class="content-item" v-if="eventInfo.url">
                <div class="title">{{ $t('events.page.url') }}</div>
                <div class="content"><a target="_blank" class="link" :href="eventInfo.url">{{ eventInfo.url }}</a></div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <createEvent
        v-else
        editMode="editEvent"
        :eventInfo="eventInfo"
        :eventId="eventInfo.id"
        @cancelEditing="cancelEditing"
      />
      <!--<edit-event v-else
                  @cancelEditing="cancelEditing"></edit-event>//-->

    </div>

    <transition name="fade-popup">
      <join-event
        v-if="$route.name === 'join-event'"
        @close="$router.push({name: 'event-info'})"
      />
    </transition>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import createEvent from './CreateEvent';
import LocationPicker from './components/locationPicker/locationPicker';
import baiduMap from './components/locationPicker/baiduMap';
import QrcodeVue from 'qrcode.vue'
import SeoHelper from '@/_helpers/seo-helper';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import joinEvent from '@/_modules/events/components/join-event/join-event.vue';

const ENVIRONMENT_NAME = process.env.VUE_APP_ENV;
const ANDROID_APK_URL_CN = 'https://app.eventswallet.cn/downloads/android/eventswallet.apk';

export default {
  name: 'event-page',
  components: {
    LocationPicker,
    createEvent,
    baiduMap,
    QrcodeVue,
    PromoSideBarLeft,
    PromoSideBarRight,
    joinEvent,
  },
  computed: {
    ...mapState(
      'eventStore', ['eventInfo', 'showPromoCheckAccessPopup', 'page_status'],
    ),
    ...mapGetters({
      eventFromNewStore: '_eventStore/event',
      eventSettings: '_eventStore/eventSettings',
      showEventAccessDisabledPopup: 'eventStore/showEventAccessDisabledPopup',
      isAuthenticated: 'authStore/isAuthenticated',
      isAuthLoading: 'authStore/isLoading'
    }),
    iosAppUrl() {
      return 'https://apps.apple.com/ua/app/events-wallet/id1506448963?l=' + this.$i18n.locale;
    },
    iosAppQrCodeContents() {
      if (this.isCN) {
        return this.iosAppUrl;
      }
      return [
        'https://app-link.eventswallet.com/?link=',
        'https://' + window.location.host + '/' + this.$i18n.locale + '/events/',
        this.$route.params.eventId,
        '&apn=com.biletum&isi=1506448963&ibi=com.biletum.edsson.Biletum',
      ].join('');
    },
    androidApkUrl() {
      return ANDROID_APK_URL_CN;
    },
    isCN() {
      return (ENVIRONMENT_NAME === 'cn');
    },
    isSectionHeroDarkenerDisabled() {
      let disableOnEventIds = ['548'];
      const requestedEventId = this.$route.params.eventId;
      const isProduction = (ENVIRONMENT_NAME === 'fr' || ENVIRONMENT_NAME === 'cn');
      if (isProduction) {
        disableOnEventIds = ['881'];
      }
      return (!!disableOnEventIds.find(id => id === requestedEventId));
    },
    getTicketsButtonAttributes() {
      if (!this.eventInfo) {
        return {
          href: null,
          target: null,
        };
      }

      const result = {
        href: null,
        target: null,
        buttonText: '',
      }

      if (this.eventInfo.access_type === 'free'
        && this.eventSettings
        && this.eventSettings.layout
        && this.eventSettings.layout.use_quick_register
      ) {
        result.buttonText = this.$t('eventPage.edit.quickRegisterTitle');
        result.href = this.$router.resolve({
          name: 'join-event'
        }).href;
        result.target = '_self';
      } else {
        result.href = this.eventInfo.buy_ticket_url;
        result.target = '_blank';
        result.buttonText = this.$t('button.buyTickets');
      }

      return result;
    },
    heroSectionStyles() {
      const result = {};
      if (this.eventInfo.image) {
        result.backgroundImage = 'url(' + this.eventInfo.image + ')';
      }
      return result;
    },
    getMapProvider() {
      let map_provider = process.env.VUE_APP_MAP_PROVIDER;
      if (this.$route.query && this.$route.query.mp && this.$route.query.mp === 'baidu') {
        map_provider = 'baidu';
      }
      return map_provider;
    },
    eventTitleClasses() {
      const titleLength = this.eventInfo.title.length;
      return {
        'title-short': titleLength <= 30,
        'title-long': (titleLength > 30) && (titleLength <= 60),
        'title-very-long': titleLength > 60
      }
    },
    showSections() {
      return {
        qr: true, // TODO: obtain specification,
        about: this.eventInfo.agendas || this.eventInfo.descr,
        media: this.eventInfo.photos,
        venue: this.eventInfo.address,
        contacts: (this.eventInfo.org_email || this.eventInfo.url || this.eventInfo.org_name || this.eventInfo.org_phone)
      }
    },
    locationPickerAddress() {
      return [
        this.eventInfo.address,
        this.eventInfo.country.name,
        this.eventInfo.city.name
      ].filter(x => x).join(', ');
    },
    isEventDisabledPopupVisible() {
      return this.showEventAccessDisabledPopup;
    }
  },
  watch: {
    eventFromNewStore: { // TODO: rewrite this whole damn file. Use only new _eventStore https://biletum.myjetbrains.com/youtrack/issue/AW-2422
      deep: true,
      immediate: false,
      handler(newVal) {
        if (newVal && newVal.personal && newVal.personal.has_access) {
          this.$store.dispatch('eventStore/setEventPersonalHasAccess', newVal.personal.has_access);
          // this.$store.dispatch('authStore/setAuthPopupVisible', {isAuthPopupVisible: false})
        }
      }
    },
    eventInfo: {
      deep: true,
      immediate: true,
      handler() {

        this.setEventInfo();
        this.manageSeoInfo();

        if (this.eventInfo.agendas) {
          this.preparedAgenda = this.prepareAgenda(this.eventInfo.agendas);
        }
      }
    },
    showPromoCheckAccessPopup: {
      immediate: true,
      handler() {
        this.showPromoAccessPopup = this.showPromoCheckAccessPopup;
      }
    }
  },
  data: () => ({
    isEventDisabledPopupVisibleFlag: false,
    qrCodeUrl: window.location.href,
    edit: false,
    current: 1,
    preparedAgenda: [],
    formData: {
      id: null,
      title: null,
      address: null,
      country_id: null,
      city_id: null,
      date_start: null,
      date_end: null,
      categories: null,
      event_type_id: null,
      image: '',
      photos: [],
      email: '',
      phone: '',
      organizer: '',
      descr: '',
      agendas: '',
      url: null,
    },
    showPromoAccessPopup: false,
    gotoPromoPageValue: null,
  }),
  created() {
    this.$store.dispatch('eventStore/event', this.$route.params.eventId);
  },
  mounted() {
    this.checkPromoCheckPopup();
    this.$store.dispatch('sideBarRightStore/close');
  },
  methods: {

    manageSeoInfo() {
      if (this.eventInfo) {
        const eventInfo = this.eventInfo;
        const descriptionTemplate = eventInfo.descr ? this.$t('metaTagTemplates.eventPage.description') : this.$t('metaTagTemplates.eventPage.descriptionDefault');
        SeoHelper.addEventPageMetaTags(eventInfo, {
          title: this.$t('metaTagTemplates.eventPage.title'),
          description: descriptionTemplate,
        });
      } else {
        SeoHelper.resetMetaTagsContent();
      }
    },

    closeEventDisabledPopup() {
      this.$store.dispatch('eventStore/hideEventAccessDisabledPopup');
    },

    async checkPromoCheckPopup() {
      if (this.showPromoCheckAccessPopup === true && this.page_status !== 'loading') {
        await this.showAuthPopup();
      }
    },

    async showAuthPopup(payload) {
      const defaultPayload = { isAuthPopupVisible: true, targetRoute: null };
      const { isAuthPopupVisible, targetRoute } = (payload ? payload : defaultPayload); // types: boolean, Route
      // await this.$store.dispatch('authStore/setAuthPopupVisible', { isAuthPopupVisible, targetRoute });
      await this.$store.dispatch('authStore/setAuthPopupTargetRoute', targetRoute)
      this.$store.dispatch('authStore/setShowStandaloneAuth', true)
    },

    getAddressClassNames() {
      if (!this.eventInfo.address) {
        return;
      }
      const len = this.eventInfo.address.length;
      // decrease in font-size for longer addresses
      return {
        'wrap-subtitle-textlength-1': len > 43 && len < 100,
        'wrap-subtitle-textlength-2': len >= 100,
      }
    },

    prepareAgenda(str) {
      if (!str) return [];
      return str.split(/(?:\r\n|\r|\n)/).filter(x => x);
    },

    toggleParticipation() {
      this.$store.dispatch('eventStore/toggleParticipation', {
        event_id: this.$route.params.eventId,
        going: this.eventInfo.personal.is_going
      });
    },
    cancelEditing(eventId) {
      if (typeof eventId !== 'undefined') {
        this.$store.dispatch('eventStore/event', eventId);
      }
      this.edit = false;
    },
    setEventInfo() { //TODO: remove form data, get from store
      this.formData.title = this.eventInfo.title;
      if (this.eventInfo.address) {
        this.formData.address = this.eventInfo.address;
      }
      if (this.eventInfo.country) {
        this.formData.country_id = this.eventInfo.country.name;
      }
      if (this.eventInfo.city) {
        this.formData.city_id = this.eventInfo.city.name;
      }
      if (this.eventInfo.date_start) {
        this.formData.date_start = this.eventInfo.date_start;
      }
      if (this.eventInfo.date_end) {
        this.formData.date_end = this.eventInfo.date_end;
      }
      if (this.eventInfo.categories) {
        this.formData.categories = this.eventInfo.categories.map(item => item);
      }
      if (this.eventInfo.event_type) {
        this.formData.event_type_id = this.eventInfo.event_type.name;
      }
      if (this.eventInfo.image) {
        this.formData.image = this.eventInfo.image;
      }
      if (this.eventInfo.photos) {
        this.formData.photos = this.eventInfo.photos.map(item => item);
      }
      if (this.eventInfo.descr) {
        this.formData.descr = this.eventInfo.descr;
      }
      if (this.eventInfo.agendas) {
        this.formData.agendas = this.eventInfo.agendas
      }
      if (this.eventInfo.url) {
        this.formData.url = this.eventInfo.url;
      }
      if (this.eventInfo.access_type) {
        this.formData.access_type = this.eventInfo.access_type;
      }
    },

    async goToPromoPage(value) {

      this.gotoPromoPageValue = value;

      this.$store.dispatch('eventStore/hidePromoCheckAccessPopup');

      if (this.eventInfo.personal.has_access !== true) {

        // Default route when navigating from the sideBar
        let targetRoute = { // type: Route
          name: 'promo-live',
          params: { eventId: this.$route.params.eventId }
        };

        let redirectParams = null;

        switch (this.gotoPromoPageValue) {
          case 'events':
            redirectParams = {
              name: 'promo-page-events-companies',
            };
            break;
          case 'program':
            redirectParams = {
              name: 'promo-program',
            };
            break;
          case 'live':
            redirectParams = {
              name: 'promo-live',
            };
            break;
          case 'contacts':
            redirectParams = {
              name: 'promo-contacts',
            };
            break;
          case 'calendar':
            redirectParams = {
              name: 'promo-page-calendar',
            };
            break;
          case 'notes':
            redirectParams = {
              name: 'notes-list',
            };
            break;
          case 'result':
            redirectParams = {
              name: 'result',
            };
            break;
          default:
            break;
        }

        if (redirectParams) {
          redirectParams.params = { eventId: this.$route.params.eventId };
          targetRoute = redirectParams;
        }

        /* AW-1178.
         * On free events an authenticated user has_access === false until he enters the event for the first time
         * In that case, authPopup was shown for a brief moment.
         * This is against that unwanted behaviour.
         */
        if (this.isAuthenticated) {
          if (this.eventInfo.access_type === 'free') {
            await this.$store.dispatch('eventStore/toggleParticipation', {
              event_id: this.$route.params.eventId,
              going: true
            });

            this.promoAccessGranted();
            return;
          } else if (this.eventInfo && this.eventInfo.personal && !this.eventInfo.personal.has_access) {
            // this.$store.dispatch('authStore/setAuthPopupVisible', {
            //   isAuthPopupVisible: true,
            //   targetRoute: targetRoute || null,
            //   visibleScreen: 'eventAccessCodeInput' // see enum AuthScreenStep
            // });
            await this.$store.dispatch('authStore/setAuthPopupTargetRoute', targetRoute);
            await this.$store.dispatch('authStore/setShowStandaloneAuth', true);
            return;
          }
        }

        this.showPromoAccessPopup = true;

        return; // This return is needed so that promoAccessGranted() is not called

      }
      this.promoAccessGranted();
    },
    endDateNeeded() {
      return !(this.$moment(this.eventInfo.date_start).format('LL') === this.$moment(this.eventInfo.date_end).format('LL'));
    },
    promoAccessGranted() {
      this.showPromoAccessPopup = false;
      switch (this.gotoPromoPageValue) {
        case 'events':
          this.$router.push({
            name: 'promo-page-events-companies',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'program':
          this.$router.push({
            name: 'promo-program',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'live':
          this.$router.push({
            name: 'promo-live',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'contacts':
          this.$router.push({
            name: 'promo-contacts',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'calendar':
          this.$router.push({
            name: 'promo-page-calendar',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'notes':
          this.$router.push({
            name: 'notes-list',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {
          });
          break;
        case 'result':
          this.$router.push({
            name: 'result',
            params: { eventId: this.$route.params.eventId }
          }).catch(() => {});
          break;
        default:
          break;
      }

    },

    linkifyUrls(input) {
      return ChatHelper.createLinks(input);
    }
    // transformEventDate(date) {
    //   return  momentTimezone.utc(date).tz(this.eventInfo.time_region).format('DD MMM YYYY HH:mm')
    // }
  }
}
</script>

<style scoped lang="scss">
.mu-button {
  text-decoration: none !important;
}

.section:last-child {
  padding-bottom: 10rem;
}

.head-panel {
  height: 85px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button {
    background-color: $ew-blue;
    color: $ew-white;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  .panel-item {
    width: 20%;

    .panel-btn {
      margin: 4px;
    }

    .favorite {
      color: $ew-blue;
    }
  }

  .panel-item-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 2.3rem;
  }

}

.section-event-page .section-body {
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;
}

.section-content {
  .title {
    margin-top: 20px;
  }

  .content {
    margin-top: 10px;
  }
}

.section-event-page {
  margin: 0 56px 0 60px;
  padding-left: 0;
  padding-right: 0;

  .section-title {
    text-align: left;
    padding-top: 8rem;
    margin-bottom: 7rem;

    .title {
      font-size: 36px;
      color: $ew-black;
      position: relative;
    }

    .title::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      border-bottom: 1px solid $ew-black;
      width: 500%;
      bottom: 10px;
      left: -500%;
      opacity: 0.2;
    }
  }

  .section-content {
    font-size: 16px;
    color: $ew-black;
    text-align: left;
    margin-top: 60px;
  }
}

.section-hero {

  background-image: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;

  .section-body {
    padding: 124px 0 90px;
    z-index: 5;
  }

  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: $ew-black;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &.hero-light::after {
    display: none;
  }


  .title-block {
    .pre-title {
      text-align: left;
      letter-spacing: 0;
      color: $biletum-yellow;
    }

    .title {
      text-align: left;
      font-weight: bold;
      color: $ew-white;
      opacity: 1;
      line-height: 1.2;
      margin-bottom: 0.5em;
      margin-left: -0.08em; // AW-1999
      word-break: break-word;

      &-short {
        font-size: 8rem;
      }

      &-long {
        font-size: 6.5rem;
      }

      &-very-long {
        font-size: 3.8rem;
      }
    }

    .subtitle {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      > .wrap:first-child {
        padding-right: 20px;
      }

      .wrap-title {
        text-align: left;
        font-size: 27px;
        letter-spacing: 0;
        color: $ew-white;
        opacity: 1;

        &:first-child {
          margin-bottom: 20px;
        }

        .title {
          font-size: 2rem;
          margin-bottom: 0;
        }

        .time-wrap {
          font-size: 2.7rem;
        }
      }

      .wrap-subtitle {
        text-align: left;
        font-size: 1.9rem;
        letter-spacing: 0;
        color: $ew-white;
        opacity: 0.8;
        word-break: break-word;

        &-textlength-1 {
          font-size: 1.8rem;
        }

        &-textlength-2 {
          font-size: 1.7rem;
        }

      }
    }
  }

  &-actions {
    padding-top: 7.2rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .logo-box {
      width: 120px;
      height: 120px;
      border-radius: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 2.4rem;
      overflow: hidden;
      background-color: #ccc;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .event-online-statuses {
      display: inline-block;
      font-size: 2.6rem;

      & > :first-child {
        margin-bottom: 2.1rem;
      }
    }

    .event-online-status-inactive {
      color: #fff;
      opacity: 0.5;
    }

    .event-online-status-active,
    .i-will-go .label {
      color: $ew-red-lighter;
    }

    .i-will-go {
      border-radius: 15px;
      background-color: rgba(255, 255, 255, 0.3);
      padding: 1.7rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      .label {
        margin-right: 2.1rem;
        font-size: 2.3rem;
      }

      .switch-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .logo-box.qr {
      background-color: #fff;
      position: relative;

      .qr-canvas {
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;

        canvas {
          width: 100%;
          height: auto;
          max-width: 100%;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 36px;
        height: 36px;
        padding: 4px;
        background-color: #fff;
        background-image: url("../assets/images/qr-overlay-icon.svg");
        background-size: 28px;
        background-position: 4px 4px;
        background-repeat: no-repeat;
        z-index: 2;
        transform: translate(-50%, -50%);
        content: "";
      }
    }

    .organizer-logo {
      background-position: center;
      background-size: contain;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;

    .control {
      margin-left: 2.3rem;
    }

    .control-button-quick-register {
      width: auto;
      max-width: none;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .btn {
    width: 238px;
    height: 44px;
    color: $ew-white;
    font-size: 2.2rem;
    text-transform: none;
    line-height: 1;
    margin-left: 3rem;

    &:hover {
      color: $ew-white;
    }
  }

  .primary {
    background-color: $ew-red-lighter;
  }

  .secondary {
    background-color: $ew-blue;
  }
}

.section-qr {
  background-color: #fff;
  padding-bottom: 4.5rem;

  .section-title {
    margin-bottom: 0;
    padding-top: 4.2rem;
  }

  .section-content {
    font-size: 1.6rem;
    color: $ew-black;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -54px;

    .content-qr {
      word-break: break-word;
      width: 472px;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 19px;
      margin-bottom: 0;
      align-self: flex-end;
    }

    .qr-block {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        z-index: 1;
      }

      .android-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 90px;

        .android-button {
          background: url("~@/assets/qr/playmarket-btn.svg") center center;
          background-size: 100% 100%;
          width: 139px;
          height: 40px;
          border: none;
          margin-right: 36px;
        }

        .apk-button {
          background: url("~@/assets/qr/apk-btn.svg") center center;
          background-size: 100% 100%;
          width: 139px;
          height: 40px;
          border: none;
          margin-right: 36px;
        }

        .android-qr {
          background: url("~@/assets/qr/android-link.svg") center center;
          background-size: 100%;
          height: 100px;
          width: 100px;
        }

        .android-qr-cn {
          background-size: contain;
          background-image: url("~@/assets/qr/android-apk-link.svg");
          height: 100px;
          width: 100px;
        }
      }

      .ios-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ios-button {
          background: url("~@/assets/qr/applestore-btn.svg") center center;
          background-size: 100% 100%;
          width: 118px;
          height: 40px;
          border: none;
          margin-right: 36px;
        }

        .ios-qr {
          height: 100px;
          width: 100px;
        }
      }
    }
  }
}

.section-about-event {
  .section-content {
    font-size: 1.6rem;
    color: $ew-black;
    text-align: left;
  }

  .section-title {
    padding-top: 3.5rem;
  }

  .ugc {
    p,
    ul,
    ol {
      margin-bottom: 1.5rem;
    }

    li {
      margin-bottom: 0.75rem;
    }
  }

  .content-agenda {
    margin-bottom: 6rem;
    word-break: break-word;

    ul {
      margin-left: 0;
      padding-left: 0;
    }
  }

}

.statistics-block {
  width: 1088px;
  height: 254px;
  background: $ew-white 0 0 no-repeat padding-box;
  box-shadow: 30px 30px 80px #3754aa1a;
  border-radius: 25px;
  opacity: 1;
  margin: 80px auto;
  left: -10%;
  position: relative;
  display: flex;

  .block-wrap {
    width: 860px;
    height: 176px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    .block-item {
      border-left: 1px solid #3754aa1a;
      border-right: 1px solid #3754aa1a;
      width: 100%;

      .title {
        text-align: center;
        font-size: 50px;
        letter-spacing: 0;
        color: $ew-black;
      }

      .subtitle {
        text-align: center;
        font-size: 25px;
        letter-spacing: 0;
        color: $ew-black;
      }
    }
  }
}

.footer-nav,
.push {
  height: 50px;
}

.footer-nav {
  position: fixed;
  bottom: 0;
}

.address-badge {
  display: inline-block;
  margin-bottom: 20rem;
  position: relative;
  z-index: 20;
  font-size: 1.2rem;
  line-height: 1.5rem;
  background-color: $ew-white;
  border-radius: 25px;
  padding: 17px 24px 24px;
  box-shadow: 30px 30px 80px rgba(55, 84, 170, 0.1);

  .badge-title,
  .badge-section-title {
    font-weight: bold;
    text-transform: uppercase;
  }

  .badge-title,
  .badge-section {
    margin-bottom: 1em;
  }

  .badge-section-content {
    word-break: break-word;
  }

  .badge-section:last-child {
    margin-bottom: 0;
  }

}

.section-media {
  .section-title {
    margin-bottom: 2.4rem;
  }

  .carousel-wrap {
    margin: 0 auto;

    img {
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

.section-venue {
  .section-body {
    padding-bottom: 0;
  }
}

.section-venue-map {
  .map-wrapper {
    position: absolute;
    margin-top: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.head-panel .i-will-go {
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  color: #ffa087;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5rem;

  .label {
    margin-right: 1.8rem;
    font-size: 1.8rem;
  }

  .switch-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.circular-progress-overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 1s;
}

.part-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.access-status {
  color: $biletum-yellow;
}

.control-button-primary,
.control-button-secondary {
  height: 36px;
  font-size: 16px;
}

.event-disabled-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 3, 10, 0.65);
  z-index: $z-index-side-bars - 5;

  .boxie {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: $ew-box-shadow;
    z-index: 999;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #000;

    button {
      display: inline-block;
      margin-top: 10px;
      -webkit-appearance: none;
      border-radius: 99px;
      border-style: solid;
      background-color: transparent;
      border-width: 1px;
      color: $ew-blue;
      border-color: $ew-blue;
      cursor: pointer;
      line-height: 3.6rem;
      font-size: 1.6rem;
      min-width: 155px;
    }
  }

}

.fade-event-access-popup-enter-active,
.fade-event-access-popup-leave-active {
  transition: opacity 0.3s;
}

.fade-event-access-popup-enter,
.fade-event-access-popup-leave-to {
  opacity: 0;
}
</style>
