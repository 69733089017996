


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { EventAccessType, TEvent, TEventSettings } from '@/_types/event.type';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import { TContactTag } from '@/_types/contact-tag.type';
import ValidationHelper from '@/_helpers/validation.helper';
import eventApi, { TRegisterContactParams, TRegisterContactResponse } from '@/_modules/events/api/event/event.api';
import SignIn from '@/_components/auth/sign-in/sign-in.vue';
import IconPasswordEye from '@/_modules/icons/components/icon-password-eye.vue';
import { Route } from 'vue-router';
import _cloneDeep from 'lodash.clonedeep';
import { AuthScreenStep } from '@/_types/auth-screen-step.enum';
import UtilsHelper from '@/_helpers/utils.helper';
import {
  AUTH_SOURCE_LOCAL_STORAGE_ITEM_NAME,
  AuthSources
} from '@/_modules/gtm-tracking/track-userid-userstatus/track-userid-userstatus.vue';

export type TJoinEventFormData = {
  name: string;
  surname: string;
  company_name: string;
  company_position: string;
  email: string;
  password: string;
  tags: number[];
}

enum JoinEventScreenNames {
  REGISTER_CONTACT = 'register-contact',
  SIGN_IN = 'sign-in',
}

@Component({
  components: {
    SignIn,
    IconPasswordEye
  }
})
export default class JoinEvent extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('_eventStore/eventContactTags') eventContactTags: TContactTag[];
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/authPopupTargetRoute') authPopupTargetRoute: Route;
  @Getter('_userStore/user') user: TUser;
  @Getter('promoPageStore/contact') myself: TContact;

  public JoinEventScreenNames: typeof JoinEventScreenNames = JoinEventScreenNames;
  public activeScreenName: JoinEventScreenNames = JoinEventScreenNames.REGISTER_CONTACT;

  public formData: TJoinEventFormData = {
    name: '',
    surname: '',
    company_name: '',
    company_position: '',
    email: '',
    password: '',
    tags: [],
  }

  public formDataErrors: { [key: string]: string} = {
    name: '',
    surname: '',
    company_name: '',
    company_position: '',
    email: '',
    password: '',
    tags: '',
  }

  public isPopupVisible: boolean = false;
  public passwordVisibilityEnterPassword: boolean = false;
  public isFormSending: boolean = false;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get deviceId(): string {
    return this.$store.state.authStore.deviceId;
  }

  public get passwordInputTypeAttribute(): string {
    if (this.passwordVisibilityEnterPassword) {
      return 'text';
    }
    return 'password';
  }

  private togglePasswordVisibility(): void {
    this.$nextTick(() => {
      this.passwordVisibilityEnterPassword = !this.passwordVisibilityEnterPassword;
    });
  }

  public get isSubmitButtonDisabled(): boolean {

    if (this.isFormSending) {
      return true;
    }

    const isFirstNameEmpty: boolean = this.formData.name.trim() === '';
    const isLastNameEmpty: boolean = this.formData.surname.trim() === '';
    const isCompanyNameEmpty: boolean = this.formData.company_name.trim() === '';
    const isJobPositionEmpty: boolean = this.formData.company_position.trim() === '';
    const isEmailEmpty: boolean = this.formData.email.trim() === '';
    const isPasswordEmpty: boolean = this.formData.password.trim() === '';

    return isFirstNameEmpty || isLastNameEmpty || isCompanyNameEmpty || isJobPositionEmpty || isEmailEmpty || isPasswordEmpty;
  }

  private isEmailValid(): boolean {
    return ValidationHelper.isValidEmail(this.emailTrimmed);
  }

  public get emailTrimmed(): string {
    return this.formData.email.trim();
  }

  public get contactTagsProcessed(): TContactTag[] {
    return this.eventContactTags || [];
  }

  public created(): void {
    if (!this.isAuthenticated) {
      this.getContactTags();
      this.showPopup();
    }
  }

  private async getContactTags(): Promise<void> {
    await this.$store.dispatch('_eventStore/getEventContactTags');
  }

  private showPopup(): void {
    this.isPopupVisible = true;
  }

  public get isJoinEventVisible(): boolean {
    return !this.isAuthenticated && (this.event && this.event.access_type === EventAccessType.FREE);
  }

  @Watch('event', { immediate: true })
  private async onEventChange(): Promise<void> {
    // TODO: refactor — break into methods
    if (!this.event || !this.isAuthenticated) {
      if (this.event && this.event.access_type !== EventAccessType.FREE) {

        await this.$store.dispatch('authStore/setAuthPopupTargetRoute', this.$route.params && this.$route.params.redirect ? this.$router.resolve(this.$route.params.redirect).location : null);
        await this.$store.dispatch('authStore/setShowStandaloneAuth', true);
        this.close();
      }
      return;
    }

    if (this.event.personal.has_access) {
      this.redirectToDesiredRoute();
    } else {
      if (this.event.access_type === EventAccessType.FREE) {
        await this.sendSetParticipationRequest();
        this.redirectToDesiredRoute();
      } else if (this.event.access_type === EventAccessType.REGISTER) {
        this.signInScreenVisibleOnStartup = AuthScreenStep.EVENT_ACCESS_CODE_INPUT;

        await this.$store.dispatch('authStore/setAuthPopupTargetRoute', this.$route.params && this.$route.params.redirect ? this.$router.resolve(this.$route.params.redirect).location : null);
        await this.$store.dispatch('authStore/setShowStandaloneAuth', true);
        this.close();
      }
    }
  }

  @Watch('isAuthenticated')
  private onIsAuthenticatedChange(): void {
    if (this.isAuthenticated && this.authPopupTargetRoute) {
      try {
        // If not cloned, Vue throws the «dont mutate store directly» error — seems router.push mutates the given route.
        this.$store.dispatch('_userStore/menuUserGuideSwitcher', true);
        this.$router.push(_cloneDeep(this.authPopupTargetRoute));
      } catch {
        /* ignore */
      }
    }
  }

  private async sendSetParticipationRequest(): Promise<void> {
    await this.$store.dispatch('eventStore/toggleParticipation', {
      event_id: this.$route.params.eventId,
      going: true
    });
  }

  private async onSubmit(): Promise<void> {
    if (this.isSubmitButtonDisabled) {
      return;
    }

    this.isFormSending = true;

    this.clearFieldErrors();

    if (!this.isFormDataValid()) {
      this.isFormSending = false;
      return;
    }

    const payload: TRegisterContactParams = this.formDataToRegisterContactConverter(this.formData);
    const result: any = await eventApi.registerContact(payload);

    if (result && result.token) {
      await this.$store.dispatch('authStore/authSuccess', {token: result.token});
      await this.$store.dispatch('_userStore/getUser');
      this.$store.dispatch('_userStore/menuUserGuideSwitcher', true);
      this.saveAuthSourceForGTM();
      this.redirectToDesiredRoute();
      await this.$store.dispatch('promoPageStore/refresh'); // AW-1622
      return;
    } else {
      this.handleSaveError(result);
    }

    this.isFormSending = false;

  }

  public isUserExistsErrorVisible: boolean = false;
  public signInScreenVisibleOnStartup: AuthScreenStep = null;

  private handleSaveError(saveResult: TRegisterContactResponse): void {
    if (saveResult.responseType === 'user_exists') {
      this.showUserExistsError();
    }
  }

  private showUserExistsError(): void {
    this.formDataErrors.email = this.$t('error.emailAlreadyRegistered') as string;
    this.isUserExistsErrorVisible = true;
  }

  private clearUserExistsError(): void {
    this.clearErrorByFieldName('email');
    this.signInScreenVisibleOnStartup = null;
    this.isUserExistsErrorVisible = false;
  }

  private isFormDataValid(): boolean {
    let result = true;

    if (!this.isEmailValid()) {
      this.setErrorByFieldName('email', this.$t('errors.validation.email_invalid') as string);
      result = false;
    }

    if (this.formData.name.trim() === '') {
      this.setErrorByFieldName('name', this.$t('contacts.popups.contactInfoInviter.fieldErrors.name') as string);
      result = false;
    }

    if (this.formData.surname.trim() === '') {
      this.setErrorByFieldName('name', this.$t('contacts.popups.contactInfoInviter.fieldErrors.lastName') as string);
      result = false;
    }

    if (this.formData.company_name.trim() === '') {
      this.setErrorByFieldName('name', this.$t('contacts.popups.contactInfoInviter.fieldErrors.companyName') as string);
      result = false;
    }

    if (this.formData.company_position.trim() === '') {
      this.setErrorByFieldName('name', this.$t('contacts.popups.contactInfoInviter.fieldErrors.jobPosition') as string);
      result = false;
    }

    if (this.formData.password.trim() === '') {
      result = false;
    }

    return result;
  }

  private formDataToRegisterContactConverter(params: TJoinEventFormData): TRegisterContactParams {
    return {
      event_id: this.eventId,
      platform: 'WEB',
      device_id: this.deviceId,
      tags: params.tags,
      email: this.emailTrimmed,
      password: this.formData.password,
      name: this.formData.name,
      surname: this.formData.surname,
      company_name: this.formData.company_name,
      company_position: this.formData.company_position,
    };
  }

  private redirectToDesiredRoute(): void {
    const desiredRoute: string = this.$route.params.redirect;
    if (desiredRoute) {
      this.$router.push(desiredRoute);
    } else {
      this.redirectToDefaultRoute();
    }
  }

  private redirectToDefaultRoute(): void {
    const defaultRouteName = UtilsHelper.getDefaultRouteNameFromEventSettings(this.eventSettings);
    try {
      this.$router.push({
        name: defaultRouteName,
        params: {
          eventId: this.$route.params.eventId
        },
      }).catch(() => {
        this.close();
      });
    } catch {
      this.close();
    }
  }

  private clearFieldErrors(): void {
    Object.keys(this.formDataErrors).forEach(fieldName => {
      this.clearErrorByFieldName(fieldName);
    });
  }

  private setErrorByFieldName(fieldName: string, value: string): void {
    this.formDataErrors[fieldName] = value;
  }

  private clearErrorByFieldName(fieldName: string): void {
    this.setErrorByFieldName(fieldName, '');
  }

  private showSignInScreen(): void {
    this.signInScreenVisibleOnStartup = null;
    this.setActiveScreen(JoinEventScreenNames.SIGN_IN);

    const targetRoute: string = this.$route.params.redirect;
    if (targetRoute) {
      this.$store.dispatch('authStore/setAuthPopupTargetRoute', this.$router.resolve(targetRoute).location);
    }
  }

  private showRegisterContactScreen(): void {
    this.setActiveScreen(JoinEventScreenNames.REGISTER_CONTACT);

    this.$store.dispatch('authStore/clearAuthPopupTargetRoute');
  }

  private setActiveScreen(newActiveScreenName: JoinEventScreenNames): void {
    this.activeScreenName = newActiveScreenName;
  }

  private onEmailKeyup(): void {
    this.clearUserExistsError();
  }

  private close(): void {
    this.$emit('close');
  }

  // AW-2721
  private saveAuthSourceForGTM(): void {
    if (!window || !window.localStorage) {
      return;
    }

    window.localStorage.setItem(AUTH_SOURCE_LOCAL_STORAGE_ITEM_NAME, AuthSources.SIGN_UP);
  }

}

